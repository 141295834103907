<template>
  <custom-collapse
    class="assignment-timeslot"
    :class="{'highlighted': isHighlighted}"
    open>
    <template #header>
      <div class="timeslot-label">
        {{ timeslot.label }}
      </div>
      <div class="orders-count">
        {{ $tc('assignment.leftPanel.timeslot.ordersCount', timeslotTasks.length, { count: timeslotTasks.length }) }}
      </div>
      <div class="order-map-marker">
        <timeslot-map-marker
          :id="timeslot.id"
          :startTime="timeslot.startTime"/>
      </div>
    </template>
    <div class="assignment-timeslot-tasks">
      <assignment-task
        v-for="task in timeslotTasks"
        :key="`left_panel_task_${task.orderIdentifier}`"
        :task="task"
        :mapMarkerVisible="false"
        :selectable="true"
        @show-pickupp-form="(task) => $emit('show-pickupp-form', task)"/>
    </div>
  </custom-collapse>
</template>

<script>
import { TASK_STATES } from '@js/constants'
import { mapGetters } from 'vuex'

const AssignmentTask = () => import('@components/AssignmentTask')
const CustomCollapse = () => import('@components/CustomCollapse')
const TimeslotMapMarker = () => import('@components/TimeslotMapMarker')

export default {
  name: 'assignment-timeslot',
  components: {
    AssignmentTask,
    CustomCollapse,
    TimeslotMapMarker
  },
  props: {
    timeslot: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('task', [
      'highlightedTask',
      'tasks'
    ]),
    timeslotTasks() {
      return this.tasks.filter(task => task.startTime === this.timeslot.startTime)
    },
    isHighlighted() {
      return this.highlightedTask && this.highlightedTask.state === TASK_STATES.INCOMING && this.highlightedTask.startTime === this.timeslot.startTime
    }
  }
}
</script>

<style lang="scss" scoped>
.assignment-timeslot {
  &.open {
    &::v-deep .custom-collapse-header {
      background-color: $grey-darkest;
    }
  }

  .orders-count {
    position: relative;
    top: 2px;
    flex-grow: 1;
    font-weight: normal;
    color: $grey;
    @extend %body;
  }
}
</style>
